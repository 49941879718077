import dateFormat from "dateformat"
import React from "react"
import { Container } from "react-bootstrap"
import handover from "../../images/icons/handover.svg"
import paymentplan from "../../images/icons/payment-plan.svg"
import priceicon from "../../images/icons/price.svg"
import roi from "../../images/icons/return-on-investment.svg"
import visa from "../../images/icons/visa.svg"
import { formatNumber, parseHTML } from "../../lib/utils"
import "./OffplanAbout.scss"
const OffplanAbout = ({ offplan }) => {
  const {
    long_description,
    price,
    display_price,
    payment_plan_text,
    currency,
    description,
    amenities,
    extra,
  } = offplan

  // if completion year's december is less than current month, then it is ready
  const isReady = offplan?.extra?.completion_date
    ? new Date(new Date(offplan?.extra?.completion_date)?.setMonth(12)) <
      new Date()
    : false

  return (
    <div className="about-offplan-wrap section-l-m" id="offplan-details">
      <Container className="about-offplan-container">
        <div className="left-section">
          <p className="heading">{parseHTML(description)}</p>
          <div className="content">
            {parseHTML(long_description)}
            {/* {parseHTML(amenities_description)} */}

          </div>
          {amenities && amenities?.length > 0 && (
            <div className="new-text-block">

              <h6>Amenities</h6>
              <ul>
                {amenities?.map((item, i) => {
                  return item !== "" && <li>{item}</li>
                })}
              </ul>
            </div>
          )}
          <ul className="list-inline property-detail-desc-key-list">
            {offplan?.extra?.min_bedrooms && (
              <li className="list-inline-item">
                <div className="d-block align-items-center property-detail-desc-key-wrapper">
                  <div className="property-detail-desc-key-bg ">
                    Min Bedrooms
                  </div>
                  <span>{offplan?.extra?.min_bedrooms}</span>
                </div>
              </li>
            )}
            {offplan?.extra?.max_bedrooms && (
              <li className="list-inline-item">
                <div className="d-block align-items-center property-detail-desc-key-wrapper">
                  <div className="property-detail-desc-key-bg ">
                    Max Bedrooms
                  </div>
                  <span>{offplan?.extra?.max_bedrooms}</span>
                </div>
              </li>
            )}
            {/* {offplan?.extra?.visa && (
                  <li className="list-inline-item">
                    <div className="d-block align-items-center property-detail-desc-key-wrapper">
                      <div className="property-detail-desc-key-bg ">
                      Visa
                      </div>
                      <span>{offplan?.extra?.visa}</span>
                    </div>
                  </li>
                )} */}
            {offplan?.extra?.custom_type && (
              <li className="list-inline-item">
                <div className="d-block align-items-center property-detail-desc-key-wrapper">
                  <div className="property-detail-desc-key-bg ">
                    Type
                  </div>
                  <span>{offplan?.extra?.custom_type}</span>
                </div>
              </li>
            )}
            {offplan?.extra?.completion_date_notes && (
              <li className="list-inline-item">
                <div className="d-block align-items-center property-detail-desc-key-wrapper">
                  <div className="property-detail-desc-key-bg ">
                    Completion Date Notes
                  </div>
                  <span>{offplan?.extra?.completion_date_notes}</span>
                </div>
              </li>
            )}

          </ul>
          {offplan?.qr_code?.length > 0 &&
            <div>
              <div className="qr-code-module border-0 p-0">
                <div className="image-section">
                  <img src={offplan?.qr_code[0]?.srcUrl} alt="scan_code" />
                </div>
                <div className="qr-content">
                  {offplan?.extra?.permit_number && <>
                    <div className="icon-text-holder">
                      <h5>DLD Permit Number</h5>

                    </div>

                    <p>{offplan?.extra?.permit_number}</p>
                  </>}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="right-section">
          {extra?.completion_date && (
            <div className="item-bk">
              <div className="icon">
                <img src={handover} alt="icon" />
              </div>
            <div className="item-wrap">
              <p className="content">
                {dateFormat(extra?.completion_date, "yyyy")}
              </p>
              <p className="heading">Handover</p>
            </div>
            </div>
          )}
          {(!!price || !!display_price) && (
                        <div className="item-bk">
                        <div className="icon">
                          <img src={priceicon} alt="icon" />
                        </div>
          
            <div id="payment-plans" className="item-wrap">
              <p className="content">
                {currency || "AED"} {display_price || formatNumber(price)}
              </p>
              <p className="heading">Starting from price</p>
            </div>
            </div>
          )}

          {extra?.payment_plan && (
                        <div className="item-bk">
                        <div className="icon">
                          <img src={paymentplan} alt="icon" />
                        </div>
          
            <div className="item-wrap">
              <p className="content">{extra?.payment_plan}</p>
              <p className="heading">Payment Plan</p>
            </div>
            </div>
          )}
                    {extra?.return_on_investment && (
                                  <div className="item-bk">
                                  <div className="icon">
                                    <img src={roi} alt="icon" />
                                  </div>
                    
            <div className="item-wrap">
              <p className="content">{extra?.return_on_investment}</p>
              <p className="heading">Return on Investment</p>
            </div>
            </div>
          )}
              {extra?.visa && (
                   <div className="item-bk">
                   <div className="icon">
                     <img src={visa} alt="icon" />
                   </div>
     
            <div className="item-wrap">
              <p className="content">{extra?.visa}</p>
              <p className="heading">Visa</p>
            </div>
            </div>
          )}
           {/* {extra?.custom_type && (
            <div className="item-wrap">
              <p className="content">{extra?.custom_type}</p>
              <p className="heading">Type</p>
            </div>
          )} */}
        </div>
      </Container>
    </div>
  )
}

export default OffplanAbout
