import { graphql } from "gatsby"
import { isEmpty } from "lodash"
import React, { useState } from "react"
import { PageLinks } from "../common/site/page-static-links"
import BannerOffplan from "../components/BannerOffplan/BannerOffplan"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import OffplanAbout from "../components/OffplanAbout/OffplanAbout"
import OffplanAttractions from "../components/OffplanAttractions/OffplanAttractions"
// import OffplanBrochure from "../components/OffplanBrochure/OffplanBrochure"
import OffPlanTileBlock from "../components/OffPlanTileBlock/OffPlanTileBlock"
import OffplanImages from "../components/OffplanImages/OffplanImages"
import OffplanNavBar from "../components/OffplanNavBar/OffplanNavBar"
import SimilarOffPlanProperties from "../components/SimilarOffPlanProperties/SimilarOffPlanProperties"
import TileBlockMap from "../components/TileBlockMap/TileBlockMap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

function OffplanTemplate(props) {
  // console.log(props?.data?.projects)
  const [offplanData, setOffplanData] = useState(props?.data?.projects)
  // const token = process.env.GATSBY_STRAPI_FORM_TOKEN

  // const getitems = async (url) => {
  //   try {
  //     const { data } = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         // "Content-Type": "multipart/form-data"
  //       },
  //     }) // could be from env files
  //     setOffplanData(data)
  //     // console.log("data", data)
  //   } catch (error) {
  //     // cache it if fail/error;
  //     console.error(error)
  //   }
  // }

  // useEffect(() => {
  //   const url = `${process.env.GATSBY_STRAPI_SRC}/api/stb-feed-qobrix/qobrix-projects/${props?.pageContext?.id}`
  //   getitems(url)
  // }, [])

  const offplan = offplanData

  const {
    // characteristics_module,
    imagetransforms,
    strapi_id,
    location_tile,
    // amenities,
    // video_module,
  } = offplan

  let processedImages = offplan?.imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})
  // const popularSearchDetailsObject = {
  //   // department: props.page_url_data.departmentVal,
  //   searchType: property.search_type,
  //   // action: property.search_type === "sales" ? "for-sale" : "for-rent",
  //   area: property.display_address,
  //   // postcode: props.page_url_data.areaVal,
  //   // bedrooms: props.page_url_data.bedVal,
  //   // propertyType: props.page_url_data.buildingval,
  //   // minprice: props.page_url_data.minpriceVal,
  //   // maxprice: props.page_url_data.maxpriceVal,
  // }

  const Head = (props) => {
    const { offplan } = props
    const curUrl = typeof window !== "undefined" ? window.location.href : ""

    const ldJson = {
      "@context": "https://schema.org",
      "@type": "Residence",
      address: offplan?.display_address,
      description: offplan?.display_address,
      name: offplan?.slug.replace(/-/g, " "),
      photo: offplan?.images?.length>0?offplan?.images[0]?.url:"",
      mainEntityOfPage: {
        "@type": "ItemPage",
        "@id": curUrl
        }
    }
    
    
    return (
      <Seo
        title={`off plan ${offplan?.extra?.custom_type} for ${offplan?.search_type} with ${offplan?.bedroom} ${offplan?.bedroom > 1 ? "bedrooms" : "bedroom"} in ${offplan?.display_address} at ${offplan?.price}.`}
        description={`Know the details of off plan ${offplan?.extra?.custom_type} for ${offplan?.search_type} with ${offplan?.bedroom} ${offplan?.bedroom > 1 ? "bedrooms" : "bedroom"} in ${offplan?.display_address} at ${offplan?.price}. Book a viewing to get assistance in finding the right ${offplan?.extra?.custom_type} for you.`}
      >
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
      </Seo>
    )
  }
  return (
    <Layout
      footerContact="footercontact"
      // popularSearchDetailsObject={popularSearchDetailsObject}
      popularSearch="residential-detail"
    >
      <div className="layout-padding-top" />
      <Head offplan={offplan} />
      <BreadcrumbModule
        isproperty
        parentlabel={`${_.capitalize(offplan?.address?.address3)}`}
        parentlink={`${
          PageLinks.obuy
        }/in-${offplan?.address?.address3?.toLowerCase()?.replace(/ /g, "-")}`}
        // subparentlabel={`${_.capitalize(offplan?.address?.address4)}`}
        // subparentlink={`${PageLinks.obuy}/in-${offplan?.address?.address3
        //   ?.toLowerCase()
        //   ?.replace(/ /g, "-")}/in-${offplan?.address4}`}
        title={offplan?.display_address}
      />
      <BannerOffplan offplan={offplan} />
      <OffplanNavBar offplan={offplan} />
      <OffplanAbout offplan={offplan} />

      {!isEmpty(offplan?.images) && <OffplanImages offplan={offplan} />}
      {/* {offplan.accommodation_summary?.length > 0 && ( */}
      {(offplan?.extra?.distance_to_the_nearest_school || offplan?.extra?.distance_to_the_nearest_airport ||
       offplan?.extra?.distance_to_the_nearest_hospital || offplan?.extra?.distance_to_the_nearest_university ||
      offplan?.extra?.distance_to_the_nearest_shops || offplan?.extra?.distance_to_city_centre) &&
          <OffplanAttractions offplan={offplan} />
      }
        
      {/* )} */}
      {/* <OffPlanTileBlock
        slug={offplan?.address?.address3?.toLowerCase()?.replace(/ /g, "-")}
      /> */}
      {offplan?.latitude && offplan?.longitude ? (
        <TileBlockMap
          locationblock
          latitude={offplan?.latitude}
          longitude={offplan?.longitude}
          secondary_heading="Location"
          description={offplan.location_description}
          offplan={offplan}
        />
      ) : null}
      <SimilarOffPlanProperties
        allProjects={props?.data?.allProjects?.edges}
        // strapi_id={offplan?.strapi_id}
        // area={offplan?.area}
        tag="new-homes"
        showDividerLine
        title="Similar Off Plan Projects"
      />
      {/* <OffplanBrochure offplan={offplan} /> */}
      {/* <FloorPlans offplan={offplan} />
      <AmenitiesSlider amenities={amenities} />
      <PaymentPlans offplan={offplan} />
      <OffplanBrochure offplan={offplan} /><OffPlanTileBlock
      <Container
        className="video-banner-container section-l-m"
        id="offplan-video"
      >
        <VideoBanner
          bannerVideo={video_module}
          processedImages={processedImages}
          strapiID={strapi_id}
          imagename="off-plan.video_module_thumbnail.thumbnail"
        />
      </Container>
      <RegisterInterestModule offplan={offplan} /> */}
    </Layout>
  )
}

export default OffplanTemplate

// accommodation_summary {
//   distance_from_airport_amount
//   distance_from_beach_amount
//   distance_from_centre_amount
//   distance_from_hospital_amount
//   distance_from_rail_station_amount
//   distance_from_school_amount
//   distance_from_shops_amount
//   distance_from_tube_station_amount
//   distance_from_university_amount
// }

export const query = graphql`
  query ($strapi_id: Int, $area: String) {
    allProjects(
      filter: {
        strapi_id: { ne: $strapi_id }
        area: { eq: $area }
        publish: { eq: true }
      }
      limit: 3
    ) {
      edges {
        node {
          images {
            url
          }
          slug
          id
          strapi_id
          title
          display_address
          price
          price_qualifier
          bedroom
          status
          crm_id
          imagetransforms{
            images_Transforms
          }
        }
      }
    }
    projects(strapi_id: { eq: $strapi_id }) {
      slug
      id
      title
      display_address
      price
      qr_code {
        srcUrl
      }
      price_qualifier
      imagetransforms{
        images_Transforms
      }
      address {
        address1
        address2
        address3
        address4
        building_name
        building_number
        postcode
      }
      amenities
      amenities_description
      area
      bedroom
      brochure {
        srcUrl
      }
      description
      department
      strapi_id
      crm_provider
      crm_id
      extra {
        availability_status
        completion_date
        construction_stage
        created
        custom_type
        developer_name
        payment_plan
        permit_number
        modified
        ref
        project_with_type
        return_on_investment
        visa
        distance_to_the_nearest_school
        distance_to_the_nearest_airport
        distance_to_city_centre
        distance_to_the_nearest_beach
        distance_to_the_nearest_shops
        distance_to_the_nearest_hospital
        distance_to_the_nearest_university
        fact_sheet_url
        floor_plan_url
        brochure_url
        payment_plan_url
        min_bedrooms
        max_bedrooms  
        completion_date_notes

      }
      search_type
      publish
      property_url
      max_bedroom
      longitude
      long_description
      location_description
      latitude
      introtext
      images {
        srcUrl
        url
      }
      video_tour {
        srcUrl
        caption
        order
      }
    }
  }
`
